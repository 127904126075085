import React from "react";
import Helmet from "react-helmet";

import Layout from "../../components/Layout";
import Header from "../../components/Header";
import CareersTop from "../../components/cinemagraphs/CareersTop";
import IntroBlock from "../../components/IntroBlock";
import TestimonialsCareers from "../../components/TestimonialsCareers";

import styles from "./styles.module.less";

const CareersPage = ({ data }) => {
    const header = (
        <Header active={["about-us", "bay-equity-careers"]} theme="light" />
    );

    return (
        <Layout hasHero={true} header={header}>
            <Helmet>
                <title>Careers at Bay Equity | Bay Equity Home Loans</title>
                <meta
                    name="description"
                    content="Looking for a new opportunity in the home loan industry? Check our job board for a rewarding career with Bay Equity, one of the top 30 mortgage companies in the US."
                />
                <meta
                    name="keywords"
                    content="loan officer,how to become a loan officer,how much do loan officers make,loan officer jobs,loan officer job description,what is a loan officer,how to become a mortgage loan officer,what does a loan officer do,becoming a loan officer,jobs with Bay Equity,Bay Equity careers,Bay Equity jobs"
                />
            </Helmet>
            <CareersTop />
            <IntroBlock
                header="Jobs with Bay Equity"
                par="If you’ve ever wondered how to become a mortgage loan officer, then you’ve come to the right place. Browse the Bay Equity careers below and apply today to become a part of our team … a team that feels more like a family."
            />

            <div className={styles.careerBox}>
                <div className={styles.innerContainer}>
                    <link
                        rel="stylesheet"
                        type="text/css"
                        href="//reports.hrmdirect.com/employment/default/sm/settings/dynamic-embed/dynamic-iframe-embed-css.php"
                    />
                    <script
                        type="text/javascript"
                        src="//reports.hrmdirect.com/employment/default/sm/settings/dynamic-embed/dynamic-iframe-embed-js.php"
                    />
                    <div
                        id="iframeHeightDiv"
                        className={styles.iframeHeightDiv}
                        name="HRM Direct Career Site iFrame Container"
                        align="center">
                        <iframe
                            title={"HRM Direct Career Site"}
                            id="inlineframe"
                            name="HRM Direct Career Site iFrame"
                            src="//bayeq.hrmdirect.com/employment/job-openings.php?search=true&nohd=&dept=-1&city=-1&state=-1"
                            frameBorder={0}
                            allowtransparency="true"
                        />
                    </div>
                </div>
            </div>
            <div className={styles.conclusionContainer}>
                <div className={styles.conclusion}>
                    <p>
                        Do one of these job descriptions sound like you?
                        Interested in becoming a loan officer with Bay Equity or
                        taking on the challenge of any of our other non–loan
                        officer jobs? Then we want to hear from you. Apply in
                        the job board above and we’ll get back to you as soon as
                        we possibly can.
                    </p>
                </div>
            </div>
            <TestimonialsCareers />
        </Layout>
    );
};

export default CareersPage;
