import React from "react";
import TestimonialsCarousel from "../TestimonialsCarousel";

import styles from "./styles.module.less";

const Testimonials = () => {
    const title = "WHAT OUR TEAM IS SAYING";
    const items = [
        {
            id: "1",
            text:
                "With over 20 years in the mortgage lending industry, I’ve finally found a company that truly cares more about the client than the bottom line. Simply put, Bay Equity is the best mortgage lender there is.",
            name: "Mona Edick",
            title: "Area Sales Manager",
        },
        {
            id: "2",
            text:
                "I have worked for Bay Equity for over 8 years, so it’s home to me. From our operations to our sales, they set us up for success, providing us with the most current technology and tools. There’s no place I’d rather be!",
            name: "Brooke Rex",
            title: "Quality Control Auditor",
        },
        {
            id: "3",
            text:
                "I’ve finally found an employer that truly treats me like family! They listen to me, they respect me and they take my suggestions to heart. I couldn’t imagine a better place to work, and I hope to stay with them for the remainder of my career.",
            name: "Katrina Hopkins",
            title: "Senior Accountant",
        },
    ];

    if (!items || !items.length) {
        return null;
    }

    return (
        <div className={styles.TeamTestimonials}>
            <section
                className={styles.TestimonialSlider}
                data-background="photo">
                <div className={styles.inner}>
                    <TestimonialsCarousel title={title} items={items} />
                </div>
            </section>
        </div>
    );
};

export default Testimonials;
